import storage from '@/lib/storage'
import {BASE_URL} from "./baseUrl";
import store from "@/store";
import {mapGetters, mapState} from "vuex";
import {Toast} from 'vant';

export default {
    methods: {
        genHeaders(needAuth = true) {
            const headers = {
                "Accept": "text/json",
                "Content-Type": "text/json;charset=UTF-8",
                "VAUTH": "HECTIV",
            }
            if (needAuth) {
                const token = storage.getItem('vcs_token')
                if (token) {
                    headers.Authorization = 'Bearer ' + token
                }
            }
            return headers
        },
        vget(path, query = null) {
            let credentials = {
                method: 'GET',
                headers: this.genHeaders(),
            }
            if (path[0] !== '/') path = '/' + path;
            let fpath = BASE_URL + path
            if (query != null) {
                const queryString = new URLSearchParams(query).toString()
                fpath += '?' + queryString
            }
            return fetch(fpath, credentials).then(res => res.json())
        },
        vpost(path, data) {
            let body = null
            const headers = this.genHeaders();
            if (data instanceof FormData) {
                body = data
                delete(headers['Content-Type'])
            } else {
                body = JSON.stringify(data)
            }
            let credentials = {
                method: 'POST',
                headers,
                body
            }
            if (path[0] !== '/') path = '/' + path;
            return fetch(BASE_URL + path, credentials).then(res => res.json())
        },
        sget(path, query = null) {
            let headers =  this.genHeaders(false);
            headers['Authorization'] = 'PYGOPINK';
            let credentials = {
                method: 'GET',
                headers: headers,
            }
            if (query != null) {
                const queryString = new URLSearchParams(query).toString()
                path += '?' + queryString
            }
            return fetch(path, credentials).then(res => res.json())
        },
        async vpostAsync(path, data) {
            let body = null
            const headers = this.genHeaders();
            if (data instanceof FormData) {
                body = data
                delete(headers['Content-Type'])
            } else {
                body = JSON.stringify(data)
            }
            let credentials = {
                method: 'POST',
                headers,
                body
            }
            if (path[0] !== '/') path = '/' + path;
            return fetch(BASE_URL + path, credentials).then(res => res.json())
        },
        vput(path, json) {
            let credentials = {
                method: 'Put',
                headers: this.genHeaders(),
                body: JSON.stringify(json)
            }
            if (path[0] !== '/') path = '/' + path;
            return fetch(BASE_URL + path, credentials).then(res => res.json())
        },
        vdelete(path, json) {
            let credentials = {
                method: 'DELETE',
                headers: this.genHeaders(),
                body: JSON.stringify(json)
            }
            if (path[0] !== '/') path = '/' + path;
            return fetch(BASE_URL + path, credentials).then(res => res.json())
        },
        commonResHandle(json) {
            const data = json.data ?? null
            if (data && typeof (data) === 'object') {
                if (data.element) {
                    const type = data.type
                    const msg = data.message
                    switch (type) {
                        case 'success':
                            Toast.success(msg)
                            break
                        case 'warning':
                            Toast.fail(msg)
                            break
                    }
                }
            }
        },
        commonResSuccess(json) {
            const data = json.data ?? null
            if (data && typeof (data) === 'object') {
                if (data.element) {
                    const type = data.type
                    if (type === 'success') return true;
                }
            }
            return false;
        },
        syncUserInfo(onSuccess, onError = null) {
            const token = storage.getItem('vcs_token')
            if (token) {
                this.vget('/auth/me').then(res => {
                    if (res.status === 'success') {
                        this.$store.commit("setUserInfo", res.data)
                        if (onSuccess) onSuccess(res)
                    } else {
                        storage.deleteItem('vcs_token')
                        if (onError) onError()
                    }
                })
            } else {
                if (onError) onError()
            }
        }
    },
    computed: {
        ...mapState(['userInfo', 'isVip']),
        ...mapGetters(['auth', 'coreUser', 'isSuperAdmin']),
        coreUserId() {
            if (this.userInfo && this.userInfo.hasOwnProperty('core_user')) {
                return this.userInfo.core_user.id
            }
            return null
        },
    },
    filters: {
        timeF(val) {
            return (new Date(val)).toISOString().split('T')[0]
        },
        timeF2(date) {
            // 定义countTime变量，用于存储计算后的数据
            let countTime;
            // 获取当前时间戳
            let time = new Date().getTime();
            // 转换传入参数为时间戳
            let afferentTime = new Date(date).getTime();
            // 当前时间戳 - 传入时间戳
            time = Number.parseInt(`${time - afferentTime}`);
            if (time < 10000) {
                // 10秒内
                return "刚刚";
            } else if (time < 60000) {
                // 超过10秒少于1分钟内
                countTime = Math.floor(time / 1000);
                return `${countTime}秒前`;
            } else if (time < 3600000) {
                // 超过1分钟少于1小时
                countTime = Math.floor(time / 60000);
                return `${countTime}分钟前`;
            } else if (time < 86400000) {
                // 超过1小时少于24小时
                countTime = Math.floor(time / 3600000);
                return `${countTime}小时前`;
            } else {
                // 超过二十四小时（一天）且格式参数为默认"default"
                countTime = Math.floor(time / 86400000);
                //大于等于365天
                if (countTime >= 365) {
                    return `${Math.floor(countTime / 365)}年前`;
                }
                //大于等于30天
                if (countTime >= 30) {
                    return `${Math.floor(countTime / 30)}个月前`;
                }
                return `${countTime}天前`;
            }
        },
    }
}
